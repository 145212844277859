// Keep in sync with scss/vars/breakpoints.scss !!!
// Keep in sync with hardcoded values in:
//    - home-brm-platform.html
//    - zug.html

export const CssBreakpoints = {
	XS: 580,
	SM: 980,
	MD: 1220,
	LG: 1600,
}
