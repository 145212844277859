import { $$ } from './helper-functions';
import Alpine from 'alpinejs';

let interval: any;
let previousVisibility = '';
let marqueeWrap: HTMLElement;

let animated = false;
let progress = 0;
let speed = 0.075;

export function initMarquee(wrap: HTMLElement, speedOfScroll = 0.075) {

	if (marqueeWrap) {
		throw new Error('Right now, there can be one marquee per page.');
	}

	marqueeWrap = wrap;
	speed = speedOfScroll;

	// Replicate text 3x
	let text = $$('[data-part="text"]', wrap);
	if (!text) {
		return;
	}
	let clone1 = text?.cloneNode(true);
	let clone2 = text?.cloneNode(true);
	text.parentNode!.appendChild(clone1);
	text.parentNode!.appendChild(clone2);

	requestAnimationFrame(
		() => {
			animated = true;
			startAnimation();
			animationStep();
			updateHtml();
		}
	);

	if (typeof document !== 'undefined') {
		document.addEventListener('visibilitychange', visibilityHandler);
	}

}

function visibilityHandler() {
	if (document.visibilityState === 'hidden') {
		previousVisibility = 'hidden';
	} else {
		if (previousVisibility === 'hidden') {
			startAnimation();
			previousVisibility = '';
			animated = false;
			updateHtml();
			requestAnimationFrame(
				() => {
					progress = 0;
					updateHtml();
					requestAnimationFrame(
						() => {
							animationStep();
						}
					)
				}
			)
		}
	}
}

function startAnimation() {
	if (interval) {
		clearInterval(interval);
		interval = null;
	}

	interval = setInterval(
		() => { animationStep(); },
		999
	);

}

function animationStep() {

	if (progress < 1.1) {
		progress += speed;
		animated = true;
		updateHtml();
	} else {

		animated = false;
		updateHtml();

		requestAnimationFrame(
			() => {
				progress -= 1;
				updateHtml();

				requestAnimationFrame(
					() => {
						animationStep();
						updateHtml();
					}
				);
			}
		);

	}

	progress = (Math.round(progress * 100) / 100);

}


function updateHtml() {
	marqueeWrap.querySelector('[data-part="mover"]')!.classList.toggle('marquee__mover--animated', animated);
	marqueeWrap.style.setProperty('--progress', progress + '');
}

