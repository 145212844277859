import debounce from 'debounce';

let observerAvailable = (typeof window !== 'undefined') && (typeof window?.IntersectionObserver !== 'undefined');

let observer: IntersectionObserver | null = null;

let violetBackgrounds = new Set<HTMLElement>();
let normalBackgrounds = new Set<HTMLElement>();

let lock = false;

function createIntersectionObserver() {

	if (!observerAvailable) {
		return;
	}

	if (observer) {
		observer.disconnect();
		observer = null;
	}

	let navbar = document.querySelector<HTMLDivElement>('#main-menu-observed-part');

	if (!navbar) {
		console.error('No #main-menu-observed-part on page!')
		return;
	}

	let topMargin = (-1 * navbar.offsetTop) + 'px';
	let bottomMargin = (-1 * (window.innerHeight - navbar.offsetTop - navbar.clientHeight)) + 'px';

	let observerRootMargin = topMargin + ' 0px ' + bottomMargin + ' 0px';

	observer = new IntersectionObserver(
		observerTrigger,
		{
			rootMargin: observerRootMargin,
		}
	)

	violetBackgrounds.forEach((el) => observer?.observe(el));
	normalBackgrounds.forEach((el) => observer?.observe(el));

}


function observerTrigger(entries: IntersectionObserverEntry[]) {
	entries.forEach(
		(e) => {

			if (!e.isIntersecting) {

				if (lock) {
					return;
				}
				document.body.classList.remove('body-white-background');
				document.body.classList.remove('body-violet-background');
			} else {
				if (violetBackgrounds.has(e.target as HTMLElement)) {
					document.body.classList.add('body-violet-background');
				} else {
					document.body.classList.add('body-white-background');
				}

				lock = true;
				setTimeout(
					() => {
						lock = false;
					},
					50
				);
			}
		}
	)
}

export function addThingWithWhiteBackground(element: HTMLElement, isVioletBackground = false) {

	if (observerAvailable && !observer) {
		createIntersectionObserver();
	}

	if (!observerAvailable || !observer) {
		return;
	}
	observer.observe(element);

	if (isVioletBackground) {
		violetBackgrounds.add(element);
	} else {
		normalBackgrounds.add(element);
	}
}

export function removeThingWithWhitebackground(element: HTMLElement) {
	if (!observerAvailable || !observer) {
		return;
	}
	observer?.unobserve(element);
	if (violetBackgrounds.has(element)) {
		violetBackgrounds.delete(element);
	}
	if (normalBackgrounds.has(element)) {
		normalBackgrounds.delete(element);
	}
}

if (typeof window !== 'undefined') {
	window.addEventListener('resize', debounce(createIntersectionObserver, 150))
}
