export function onReady(fn: () => any) {
	if (typeof fn === 'string') {
		console.warn('Tohle není jQuery, pro hledání elementů na stránce dle selektoru použij $$!');
		return;
	}
	if (document.readyState === 'complete') {
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}

export function find<ExpectedResultType extends HTMLElement>(selector: string, parent?: HTMLElement | Document): ExpectedResultType | null
export function find<ExpectedResultType extends SVGElement>(selector: string, parent?: HTMLElement | Document): ExpectedResultType | null
export function find<ExpectedResultType extends SVGElement | HTMLElement>(selector: string, parent?: HTMLElement | Document): ExpectedResultType | null  {
	if (!parent) {
		parent = document;
	}
	return parent.querySelector(selector);
}

export const $ = onReady;
export const $$ = find;
