import { ScrollOnPath } from './scroll-on-path';
import { $$ } from './helper-functions';
import { CssBreakpoints } from './breakpoints';

export function initAboutUsText1(wrap: HTMLElement) {

	let theEffect = new ScrollOnPath(
		$$<SVGPathElement>('[data-part="path"]', wrap)!,
		$$<SVGSVGElement>('[data-part="svg"]', wrap)!,
		$$('[data-part="deco"]', wrap)!,
	);

	theEffect.isActiveFunction = () => {
		return window.innerWidth >= CssBreakpoints.SM;
	};

	theEffect.setYPosOnViewportToTarget(0.4);

	theEffect.init();

}
