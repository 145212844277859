import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import { initHomeHero } from './home-hero';
import { onReady, find } from './helper-functions';
import { initMarquee } from './marquee';
import { addThingWithWhiteBackground, removeThingWithWhitebackground } from './menu-color-service';
import { initHomeNeatlyCapture } from './home-neatly-capture';
import { initHomeVioletBlock } from './home-violet-block';
import { initPageHero } from './page-hero';
import { initSolutionInsights } from './solution-insights';
import { initSolutionOptions } from './solution-options';
import { initEasyToDeploy } from './easy-to-deploy';
import { initFaq } from './faq';
import { fakeSendingOfContactForm, initContactForm } from './contact-form';
import { initAboutUsText1 } from './about-us-text1';
import { autoTouchFormField } from './form-auto-touch';

export class Xploritas {
	homeHero = initHomeHero;
	marquee = initMarquee;
	addThingWithWhiteBackground = addThingWithWhiteBackground;
	removeThingWithWhitebackground = removeThingWithWhitebackground;
	homeNeatlyCapture = initHomeNeatlyCapture;
	homeVioletBlock = initHomeVioletBlock;
	pageHero = initPageHero;
	solutionInsights = initSolutionInsights;
	solutionOptions = initSolutionOptions;
	easyToDeploy = initEasyToDeploy;
	faq = initFaq;
	contactForm = initContactForm;
	fakeSendingOfContactForm = fakeSendingOfContactForm;
	aboutUsText1 = initAboutUsText1;
	autoTouchFormField = autoTouchFormField;
}


// @ts-ignore
window['Xploritas'] = new Xploritas();

// @ts-ignore
window['$'] = onReady;

// @ts-ignore
window['$$'] = find;


// @ts-ignore
window['Alpine'] = Alpine;
onReady(
	() => {
		Alpine.plugin(intersect);
		Alpine.start()
	}
);
