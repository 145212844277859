export function autoTouchFormField(input: HTMLInputElement) {

	input.addEventListener('invalid', () => touchControl(input));
	input.addEventListener('blur', () => touchControl(input));

}


function touchControl(input: HTMLInputElement) {
	if (input.type === 'checkbox') {
		input.classList.add('g-checkbox--touched')
	} else {
		input.classList.add('g-input--touched')
	}
}
