import { ScrollOnPath } from './scroll-on-path';
import { $$ } from './helper-functions';
import { CssBreakpoints } from './breakpoints';
import { addThingWithWhiteBackground } from './menu-color-service';

export function initHomeVioletBlock(wrap: HTMLElement) {

	let effectPath = $$<SVGPathElement>('[data-part="path"]');
	let effectSvg = $$<SVGSVGElement>('[data-part="svg"]')
	let effectBody = $$('[data-part="effect-body"]')
	let effectHeight = $$('[data-part="height"]')

	if (!effectBody || !effectHeight || !effectPath || !effectSvg) {
		return;
	}

	let theEffect = new ScrollOnPath(
		effectPath,
		effectSvg,
		effectBody,
		effectHeight
	);
	theEffect.isActiveFunction = () => {
		return (window.innerWidth >= CssBreakpoints.XS);
	};
	theEffect.init();




	addThingWithWhiteBackground(wrap, true);


}
