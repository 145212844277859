import { CssBreakpoints } from './breakpoints';
import { $$ } from './helper-functions';

export function initFaq(wrap: HTMLElement) {

	wrap.addEventListener('faqchanged', (e: any) => {

		if (e.detail !== null && window.innerWidth < CssBreakpoints.SM) {

			// On mobiles, we need to rescroll to top of the newly expanded question
			// https://jira.wdf.cz/browse/XPLOR-31#comment-49511

			setTimeout(
				() => {
					fixScrollToQuestion(wrap, e.detail);
				},
				300
			);
		}


	})

}




function fixScrollToQuestion(wrap: HTMLElement, index: number) {
	let el = $$('[data-part="item' + index + '"]', wrap);
	if (!el) {
		return;
	}
	let rect = el.getBoundingClientRect();
	if (rect.top < 0) {
		window.scrollBy({
			behavior: 'smooth',
			top: rect.top - 85 // 85 px as height of navbar
		})
	}
}
