import clamp from 'clamp';

export class ScrollOnPath {

	protected currentProgress = 0;
	protected nextTickPlanned = false;

	protected scrollListener?: () => void;

	protected _isActiveFunction?: () => boolean;

	protected cssPropertyXName = '--x';
	protected cssPropertyYName = '--y';

	protected maxSpeed = 0.01;
	protected speed = 0.06;

	protected yPosOnViewportToTarget = 0.5;

	protected translateX = 0;
	protected translateY = 0;

	protected callback: ((progress: number, xPos: number, yPos: number) => void) | null = null;


	/**
	 * @param thePath The path element that is used to animate target
	 * @param svg The SVG that contains the Path
	 * @param cssTarget The element that receives X and Y coordinates as CSS properties
	 * @param scrollElement The element that is measured for scroll position.
	 *    Target starts its motion when middle (or settable YPosOnViewportToTarget) of screen reaches top of this element
	 *    and ends its motion when middle reaches bottom of this element.
	 */
	constructor(
		protected thePath: SVGPathElement,
		protected svg: SVGSVGElement,
		protected cssTarget: HTMLElement,
		protected scrollElement: HTMLElement | null = null
	) {
		if (!this.scrollElement) {
			this.scrollElement = this.cssTarget;
		}
	}

	set isActiveFunction(fn: () => boolean) {
		this._isActiveFunction = fn;
	}

	setCssPropertyNames(x = '--x', y = '--y') {
		this.cssPropertyXName = x;
		this.cssPropertyYName = y;
	}

	setSpeed(speed: number, maxSpeed: number) {
		this.maxSpeed = maxSpeed;
		this.speed = speed;
	}

	setTranslate(x: number, y: number) {
		this.translateX = x;
		this.translateY = y;
	}

	setYPosOnViewportToTarget(pos: number) {
		this.yPosOnViewportToTarget = pos;
	}

	setCallback(fn: (progress: number, xPos: number, yPos: number) => void) {
		this.callback = fn;
	}

	init() {
		if (this.scrollListener) {
			throw new Error('init() was already called on something!')
		}
		this.scrollListener = () => {
			this.animationStep(false);
		};
		window.addEventListener('scroll', this.scrollListener);
		window.addEventListener('resize', this.scrollListener);
		this.animationStep(true);
	}

	destroy() {
		if (this.scrollListener) {
			window.removeEventListener('scroll', this.scrollListener);
			window.removeEventListener('resize', this.scrollListener);
			this.scrollListener = undefined;
		}
	}


	protected calcPosFromProgress(progress: number): {x: number, y:number} {
		progress = clamp(progress, 0, 1);
		let l = this.thePath.getTotalLength();
		let svgBox = this.svg.viewBox;
		let pos = this.thePath.getPointAtLength(l * progress);
		return {
			x: ((pos.x + this.translateX) / svgBox.baseVal.width),
			y: ((pos.y + this.translateY) / svgBox.baseVal.height)
		}
	}

	protected calcProgressFromCurrentScroll() {
		let rect = this.scrollElement!.getBoundingClientRect();
		let topPos = rect.top;
		let bottomPos = rect.top + rect.height;
		let midScreenPos = window.innerHeight * this.yPosOnViewportToTarget;
		let progress = clamp((midScreenPos - topPos) / (bottomPos - topPos), 0, 1);
		return progress;
	}

	protected animationStep(instant = false) {

		if (this._isActiveFunction && !this._isActiveFunction()) {
			return;
		}

		let progress = this.calcProgressFromCurrentScroll();

		if (instant) {
			this.currentProgress = progress;
		} else {

			if (Math.abs(this.currentProgress - progress) < 0.001) {
				this.currentProgress = progress;
			} else {
				let step = (progress - this.currentProgress) * this.speed;
				step = clamp(step, -1 * this.maxSpeed, this.maxSpeed);
				this.currentProgress += step;
				this.planNextTick();
			}
		}

		let coords = this.calcPosFromProgress(this.currentProgress);
		this.applyProgress(coords.x, coords.y);

		if (this.callback) {
			this.callback(this.currentProgress, coords.x, coords.y);
		}
	}


	protected planNextTick() {
		if (!this.nextTickPlanned) {
			this.nextTickPlanned = true;
			requestAnimationFrame(
				() => {
					this.nextTickPlanned = false;
					this.animationStep();
				},
			);
		}
	}

	protected applyProgress(xCoord: number, yCoord: number) {
		this.cssTarget.style.setProperty(this.cssPropertyXName, xCoord + '');
		this.cssTarget.style.setProperty(this.cssPropertyYName, yCoord + '');
	}

}
