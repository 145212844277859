import { $$ } from './helper-functions';

export function initSolutionOptions(wrap: HTMLElement) {

	window.addEventListener('solutionoptions', (e: any) => {
		setTimeout(
			() => {
				fixScrollToItem(wrap, e.detail);
			},
			100
		)
	});

}


function fixScrollToItem(wrap: HTMLElement, index: number) {
	let el = $$('[data-part="item' + index + '"]', wrap);
	if (!el) {
		return;
	}
	let rect = el.getBoundingClientRect();
	if (rect.top < 0) {
		window.scrollBy({
			behavior: 'smooth',
			top: rect.top - 70 // 70 px as approx. height of navbar
		})
	}
}
