import { $$ } from './helper-functions';
import Alpine from 'alpinejs';
import { throttle } from 'throttle-debounce';



export function initHomeNeatlyCapture(wrap: HTMLElement) {

	let paneWrap = $$('[data-part="pane-wrap"]', wrap);

	if (!paneWrap) {
		return;
	}

	let currentPane = 0;

	window.addEventListener('scroll', throttle(100,

		() => {
			let currentRect = paneWrap!.getBoundingClientRect()

			let objectTop = currentRect.top;
			let objectPos = currentRect.top + currentRect.height / 2;
			let midOfScreen = window.innerHeight / 2;

			if (objectPos < midOfScreen || objectTop < 80) {
				if (currentPane !== 1) {
					changePane(1);
					currentPane = 1;
				}
			} else {
				if (currentPane !== 0) {
					changePane(0);
					currentPane = 0;
				}
			}

		}

	));

}



function changePane(index: number) {
	let event = new CustomEvent('homeneatlycapture', {detail: index});
	window.dispatchEvent(event);
}
