import { addThingWithWhiteBackground } from './menu-color-service';
import { $$ } from './helper-functions';

export function initPageHero(wrap: HTMLElement) {
	addThingWithWhiteBackground(wrap);

	$$('[data-part="arrow"]', wrap)?.addEventListener('click', () => {
		scrollDown(wrap);
	})
}


function scrollDown(wrap: HTMLElement) {

	let bottomPart = $$('[data-part="bottom"]', wrap);

	if (bottomPart) {
		bottomPart.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}

}
