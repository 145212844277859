import { $$ } from './helper-functions';
import { CssBreakpoints } from './breakpoints';

export function initSolutionInsights(wrap: HTMLDivElement) {

	window.addEventListener('solutioninsightsgoto', (e: any) => {
		let pane = e.detail as number;
		scrollToContentPart(wrap, pane);
	})

}

function scrollToContentPart(wrap: HTMLElement, paneIndex: number) {
	let target = $$('[data-part="part' + paneIndex + '"]', wrap);
	if (target) {
		let top = target.getBoundingClientRect().top + window.scrollY;
		let offset = window.innerWidth < CssBreakpoints.SM ? 80 : 140; // for navbar and nice top margin
		window.scrollTo({
			behavior: 'smooth',
			top: top - offset,
		});
	}
}
