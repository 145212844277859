import { $$ } from './helper-functions';

export function initHomeHero(wrap: HTMLElement) {

	let body = $$('[data-part="body"]', wrap);
	let bg = $$('[data-part="bg"]', wrap);

	if (body && bg) {
		startAnimation(body, bg);
	}

}



function startAnimation(body: HTMLElement, bg: HTMLElement) {

	body.classList.add('state-running');
	bg.classList.add('state-running');
	setTimeout(
		() => {
			body.classList.add('state-animated');
			bg.classList.add('state-animated');
		},
		10
	);
	setTimeout(
		() => {
			bg.classList.add('state-step-1');
		},
		100
	);
	setTimeout(
		() => {
			body.classList.add('state-step-1');
		},
		600
	);
	setTimeout(
		() => {
			body.classList.add('state-step-2');
		},
		2100
	);
	setTimeout(
		() => {
			body.classList.add('state-step-3');
		},
		3100
	);
	setTimeout(
		() => {
			body.classList.add('state-step-4');
		},
		4000
	);

	setTimeout(
		() => {
			body.classList.remove('state-animated');
		},
		5000
	);

}
