import { ScrollOnPath } from './scroll-on-path';
import { $$ } from './helper-functions';
import { CssBreakpoints } from './breakpoints';

export function initEasyToDeploy(wrap: HTMLElement) {


	let thePath = $$<SVGPathElement>('[data-part="svgPath"]', wrap);
	let theSvg = $$<SVGSVGElement>('[data-part="svg"]', wrap);
	let mainContainer = $$('[data-part="main"]', wrap);

	if (!thePath || !theSvg || !mainContainer) {
		return;
	}

	let scrollEffect = new ScrollOnPath(
		thePath,
		theSvg,
		mainContainer
	);
	scrollEffect.setTranslate(52, 120);
	scrollEffect.setSpeed(0.04, 0.007)
	scrollEffect.setCallback((progress, _x, _y) => {
		let whichStep = stepHighlighter(progress);
		window.dispatchEvent(new CustomEvent<number>('easytodeploystep', {detail: whichStep}));
	});
	scrollEffect.isActiveFunction = () => {
		return (window.innerWidth >= CssBreakpoints.SM);
	};
	scrollEffect.init();


}


const importantPointsOnPath = [0.25, 0.545, 0.845];


function stepHighlighter(progress: number) {

	if (progress < importantPointsOnPath[0]) {
		return 1;
	} else if (progress < importantPointsOnPath[1]) {
		return 2;
	} else if (progress < importantPointsOnPath[2]) {
		return 3;
	} else {
		return 4;
	}
}
