import { addThingWithWhiteBackground } from './menu-color-service';
import { $$ } from './helper-functions';
import { autoTouchFormField } from './form-auto-touch';

export function initContactForm(wrap: HTMLElement) {

	addThingWithWhiteBackground(wrap);

	wrap.querySelectorAll('input').forEach(
		(i) => autoTouchFormField(i),
	);

}


export function fakeSendingOfContactForm(
	form: HTMLFormElement,
	canBeSentHandler: () => boolean,
	startSendingHandler: () => void,
	markAsSentHandler: () => void,
	resetHandler: () => void,
) {

	if (!canBeSentHandler()) {
		resetHandler();
		return;
	}

	let valid = form.checkValidity();
	if (!valid) {
		form.reportValidity();
		return;
	}

	startSendingHandler();

	setTimeout(
		() => {
			markAsSentHandler();

			setTimeout(
				() => {
					resetHandler();
				},
				2000,
			);
		},
		1500,
	);

}
